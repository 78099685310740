import store from '@/store'
import { getToken } from '@/utils/auth'; // getToken from cookie
import { Message, Loading } from 'element-ui'

var url = `${window.configPath.webSocketUrl}`
var ws;
var tt;
var lockReconnect = false;//避免重复连接
// var clientId = store.getters.userInfo.id || null;//缓存中取出客户端id

var websocket = {
    Init: function () {
        if ("WebSocket" in window) {
            ws = new WebSocket(`${url}?access-token=${getToken()}`);
        } else if ("MozWebSocket" in window) {
            // eslint-disable-next-line no-undef
            ws = new MozWebSocket(`${url}?access-token=${getToken()}`);
        } else {
            console.log("您的浏览器不支持 WebSocket!");
            return;
        }

        let loading = Loading.service({
            target: document.querySelector(".right-menu .notifications .wideal-icon-notifications"),
            // spinner: "el-icon-loading",
            background: "#172048"
        });
        setTimeout(() => {
            if (loading.visible) {
                loading.close();
            }
        }, 30000);
        ws.onmessage = function (e) {
            heartCheck.start()
            loading.close();
            if (e.data === 'ok') {//心跳消息不做处理
                return
            } else if (e.data === 'conn_success') {//心跳消息不做处理
                return
            } else {
                try {
                    if (JSON.parse(e.data)) {
                        messageHandle(JSON.parse(e.data))
                    }
                } catch (error) {
                    console.log(e.data);
                }
            }
        }

        ws.onclose = function () {
            console.log("连接已关闭")
            if (getToken()) {
                reconnect();
            } else {
                return
            }
        }

        ws.onopen = function () {
            console.log(`${+new Date()}连接成功`)
            loading.close();
            heartCheck.start();
        }

        ws.onerror = function (e) {
            console.log(`${+new Date()}消息连接失败，正在自动重试！ ${e}`);
            Message({
                message: '消息连接失败，正在自动重试！',
                type: 'error',
            });
            reconnect()
        }
    },
    Send: function (sender, reception, body, flag) {
        let data = {
            sender: sender,
            reception: reception,
            body: body,
            flag: flag
        }
        let msg = JSON.stringify(data)
        console.log("发送消息：" + msg)
        ws.send(msg)
    },
    close: function () {
        ws.close()
    },
    getWebSocket() {
        return ws;
    },
    getStatus() {
        if (ws.readyState === 0) {
            return "未连接";
        } else if (ws.readyState === 1) {
            return "已连接";
        } else if (ws.readyState === 2) {
            return "连接正在关闭";
        } else if (ws.readyState === 3) {
            return "连接已关闭";
        }
    }
}

export default websocket;

//根据消息标识做不同的处理
function messageHandle(data) {
    websocket.onmessage(data);
    // let msg = JSON.parse(message)
    // switch (msg.flag) {
    // case 'command':
    //     console.log("指令消息类型")
    //     break;
    // case 'inform':
    //     console.log("通知")
    //     break;
    // default:
    //     console.log("未知消息类型")
    // }
}

function reconnect() {
    if (lockReconnect) {
        return;
    }
    lockReconnect = true;
    //没连接上会一直重连，设置延迟避免请求过多
    tt && clearTimeout(tt);
    tt = setTimeout(function () {
        console.log(`${+new Date()}执行断线重连...`)
        websocket.Init();
        lockReconnect = false;
    }, 4000);
}

//心跳检测
var heartCheck = {
    timeout: 1000 * 60 * 3,
    timeoutObj: null,
    serverTimeoutObj: null,
    start: function () {
        console.log('开始心跳检测');
        var self = this;
        this.timeoutObj && clearTimeout(this.timeoutObj);
        this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj);
        this.timeoutObj = setTimeout(function () {
            //这里发送一个心跳，后端收到后，返回一个心跳消息，
            //onmessage拿到返回的心跳就说明连接正常
            console.log('心跳检测...');
            ws.send("HeartBeat:" + store.getters.userInfo.id, +new Date());
            self.serverTimeoutObj = setTimeout(function () {
                if (ws.readyState !== 1) {
                    ws.close();
                }
                // createWebSocket();
            }, self.timeout);

        }, this.timeout)
    }
}