// 修改密码
// popChangePwd
<template>

    <div class="popChangePwd">
        <el-dialog title="修改密码"
                   :close-on-click-modal="false"
                   :close-on-press-escape="false"
                   :visible.sync="dialogVisible"
                   :append-to-body="true"
                   :show-close="!fistLogin"
                   width="30%"
                   :before-close="close">
            <p class="tipBox"
               v-if="fistLogin"><i class="el-icon-warning-outline"></i><span>您还没有修改默认密码，为了保障您的账号安全，请修改密码！</span></p>
            <el-form :model="form"
                     ref="form"
                     :rules="formRules"
                     label-width="80px"
                     :inline="false">
                <el-form-item label="旧密码"
                              prop="oldPwd">
                    <el-input v-model="form.oldPwd"
                              type="password"
                              placeholder="请输入旧密码"
                              clearable></el-input>
                </el-form-item>
                <el-form-item label="新密码"
                              prop="newPwd">
                    <el-input v-model="form.newPwd"
                              type="password"
                              placeholder="请输入新密码"
                              clearable></el-input>
                </el-form-item>
                <el-form-item label="确认密码"
                              prop="secondNewPwd">
                    <el-input v-model="form.secondNewPwd"
                              type="password"
                              placeholder="请输入确认密码"
                              clearable></el-input>
                </el-form-item>
                <div class="footer">
                    <el-button type="danger"
                               :disabled="fistLogin"
                               @click="close">取消</el-button>
                    <el-button type="primary"
                               @click="save">保存</el-button>
                </div>
            </el-form>

        </el-dialog>

    </div>
</template>

<script>
import { Loading } from "element-ui";
import API from "@/api/commonAPI.js";
import { getPublicKey } from "@/utils/auth";
import encrypt from "@/utils/encrypt";
import store from "@/store";
export default {
    name: "popChangePwd",

    props: {
        // 是否是首次登陆，首次登陆需要修改密码
        fistLogin: {
            type: Boolean,
            default: false,
        },
    },

    components: {},

    data() {
        return {
            dialogVisible: true,
            form: {
                oldPwd: "", // 原密码
                newPwd: "", // 新密码
                secondNewPwd: "", // 二次确认
            },
            formRules: {
                // 原密码
                oldPwd: {
                    required: true,
                    message: "请输入原密码",
                    trigger: "blur",
                },
                // 新密码
                newPwd: {
                    required: true,
                    message: "请输入新密码",
                    trigger: "blur",
                },
                // 二次确认
                secondNewPwd: {
                    required: true,
                    message: "请输入新密码",
                    trigger: "blur",
                },
            },
        };
    },

    created() {},

    mounted() {
        // 获取PublicKey
        store.dispatch("GetPublicKey");
    },

    methods: {
        // 修改密码
        save() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    if (this.form.newPwd !== this.form.secondNewPwd) {
                        this.$message({
                            message: "两次密码不一致，请重新输入",
                            type: "error",
                        });
                        this.form.newPwd = "";
                        this.form.secondNewPwd = "";
                        return;
                    }
                    let loading = Loading.service({
                        target: document.querySelector(".el-dialog__body"),
                    });
                    let data = {
                        publicKey: getPublicKey(),
                        oldPwd: encrypt(this.form.oldPwd), // 原密码
                        newPwd: encrypt(this.form.newPwd), // 新密码
                        secondNewPwd: encrypt(this.form.secondNewPwd), // 二次确认
                    };
                    API.resetPwd(data)
                        .then(() => {
                            loading.close();
                            this.$message({
                                message:
                                    "密码修改成功！您的账号信息发生了变化，将自动为你跳转到登录页！",
                                type: "success",
                            });
                            store.dispatch("LogOut").then(() => {
                                this.close();
                            });
                        })
                        .catch(() => {
                            loading.close();
                        });
                }
            });
        },
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
.tipBox {
    padding: 0 30px;
    color: #f00;
    line-height: 50px;
    font-size: 14px;
    i {
        margin-right: 8px;
        vertical-align: middle;
    }
}
.footer .is-disabled {
    opacity: 0.5;
}
</style>