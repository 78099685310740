// 选择所有银行卡号
<template>
    <el-select v-model="bankId"
               filterable
               placeholder="请选择"
                @change="selectChange"
               :multiple="multiple"
               :clearable="needClean">
        <el-option v-for="item in bankList"
                   :key="item.id"
                   :label="item.name"
                   :value="item.id">
        </el-option>
    </el-select>
</template>
<script>
import API from "@/api/finance.js";
export default {
    name: "allbankList",
    components: {},
    props: {
        value: {
            type: String,
            default: "",
        },
        // 是否需要清空功能
        needClean: {
            type: Boolean,
            default: true,
        },
        // 多选
        multiple:{
            default: false
        }
    },
    data() {
        return {
            bankList: [], //银行列表
            bankId: "", //银行id
        };
    },
    mounted() {
        this.getAllBankList();
    },
    methods: {
        //获取银行列表
        getAllBankList() {
            API.getBankOpts().then((res) => {
                this.bankList = res.content;
                this.bankId = this.value;
            });
        },

        //银行选择 change事件
        selectChange(val){
            let choosePerson = this.bankList.filter((v)=>{
                return v.id === val
            });
            this.$emit("chooseData",choosePerson)
        }
    },
    computed: {},
    watch: {
        value(newValue) {
            this.bankId = newValue;
        },
        bankId() {
            this.$emit("input", this.bankId);
        },
    },
};
</script>
<style scoped>
</style>