<template>
    <div class="side"
         style="height: 100%;">
        <div class="logo">
            <h1 style="font-size:16px"
                @click="clearTagview"></h1>
        </div>
        <el-scrollbar wrap-class="scrollbar-wrapper"
                      style="height: calc(100% - 85px);">
            <el-menu :default-active="$route.path"
                     :default-openeds="openeds"
                     :collapse="isCollapse"
                     :background-color="variables.menuBg"
                     :text-color="variables.menuText"
                     :active-text-color="variables.menuActiveText"
                     mode="vertical">
                <sidebar-item v-for="route in permissionRouter"
                              :key="route.path"
                              :item="route"
                              :base-path="route.path" />
            </el-menu>
        </el-scrollbar>
    </div>
</template>

<script>
import userRoleApi from "@/api/userRoleApi.js";
import { mapGetters } from "vuex";
import SidebarItem from "./SidebarItem";
import variables from "@/styles/variables.scss";
// import router from "@/router";
export default {
    components: { SidebarItem },
    computed: {
        ...mapGetters(["sidebar"]),
        variables() {
            return variables;
        },
        isCollapse() {
            return !this.sidebar.opened;
        },
        // permissionRouter() {
        //     // return [{"path":"/contractManage","name":"contractManage","meta":{"title":"合同管理","icon":"icon_deviceManage"},"children":[{"path":"index","name":"contractManage","meta":{"title":"合同管理","icon":""}},{"path":"contractRecord","name":"contractRecord","meta":{"title":"合同记录"}}]},{"path":"/systemManage","meta":{"title":"系统用户","icon":"icon_deviceManage"},"children":[{"path":"userManagement","name":"userManagement","meta":{"title":"用户管理"}},{"path":"roleManagement","name":"roleManagement","meta":{"title":"角色管理"}},{"path":"organManagement","name":"organManagement","meta":{"title":"组织架构管理"}}]}]
        //     return router.options.routes;
        // },
    },
    data() {
        return {
            isloading: false,
            permissionRouter: [],
            openeds: [
                "/moneyManage",
                "accounts",
                "incomeManage",
                "/payManage",
                "/qichu",
                "/accounting",
                "/billManage",
                "/salary",
                "/homeConfig",
                "/settlementManage",
                "/deviceManage",
                "/generalView",
                "/reimbursement",
                "/testManage",
                "/stockManage",
                "taskManage",
                "/implementationManage",
                "costManage",
                "/reserveProject",
                "/projectManage",
                "/templateManage",
                "/approval",
                "/humanResourcesManage",
                "/baseInfoManage",
                "/systemManage",
            ],
        };
    },
    mounted() {
        this.getCurrentUserMenu();
    },
    watch: {},

    methods: {
        clearTagview(router_) {
            let parentPath = router_[0];
            this.$store.dispatch("delAllViews", this.$route);
            this.$router.push({ path: parentPath.path });
        },

        /* 获取用户菜单 */
        getCurrentUserMenu() {
            this.isloading = true;
            userRoleApi
                .getCurrentUserMenu()
                .then((res) => {
                    this.isloading = false;
                    this.permissionRouter =
                        res.content.length === 0 ? [] : res.content;
                    this.$store.commit("setRouteList", this.permissionRouter);
                })
                .catch(() => {
                    this.isloading = false;
                });
        },
    },
};
</script>

<style rel="stylesheet/scss" scoped>
.logo {
    width: 100%;
    /* height: 111px; */
    background: #172048 url("../../../../assets/imgs/logo.png") no-repeat center
        center;
    background-size: 157px 48px;
    font-size: 24px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    position: relative;
    margin: 0;
}

.logo h1 {
    margin: 0;
    height: 85px;
    font-size: 20px;
    text-align: center;
}
.hideSidebar .logo {
    background-position: 3px center;
    background-size: 100px auto;
}
</style>