<template>
    <div v-if="!item.hidden&&item.children"
         class="menu-wrapper">

        <template v-if="hasOneShowingChild(item.children,item) && (!onlyOneChild.children||onlyOneChild.noShowingChildren)&&!item.alwaysShow">
            <el-row>
                <app-link :to="resolvePath(onlyOneChild.path)">
                    <el-menu-item :index="resolvePath(onlyOneChild.path)"
                                  @click="getMeunRole"
                                  :route="onlyOneChild"
                                  :class="{'submenu-title-noDropdown':!isNest}">
                        <item v-if="onlyOneChild.meta"
                              :icon="onlyOneChild.meta.icon||item.meta.icon"
                              :title="generateTitle(onlyOneChild.meta.title)" />
                    </el-menu-item>
                </app-link>
            </el-row>
        </template>

        <el-submenu v-else
                    ref="subMenu"
                    :index="resolvePath(item.path)">
            <template slot="title">
                <item v-if="item.meta"
                      :icon="item.meta.icon"
                      :title="generateTitle(item.meta.title)" />
            </template>

            <template v-for="child in item.children">
                <template v-if="!child.hidden">
                    <sidebar-item v-if="child.children&&child.children.length>0"
                                  :is-nest="true"
                                  :item="child"
                                  :key="child.path"
                                  :base-path="resolvePath(child.path)"
                                  class="nest-menu" />

                    <app-link v-else
                              :to="resolvePath(child.path)"
                              :key="child.name">
                        <el-menu-item :index="resolvePath(child.path)"
                                      @click="getMeunRole"
                                      :route="child">
                            <item v-if="child.meta"
                                  :icon="child.meta.icon"
                                  :title="generateTitle(child.meta.title)" />
                        </el-menu-item>
                    </app-link>
                </template>
            </template>
        </el-submenu>

    </div>
</template>

<script>
import path from "path";
import { generateTitle } from "@/utils/i18n";
import { isExternal } from "@/utils/validate";
import Item from "./Item";
import AppLink from "./Link";
import FixiOSBug from "./FixiOSBug";
import userRoleApi from "@/api/userRoleApi.js";

export default {
    name: "SidebarItem",
    components: { Item, AppLink },
    mixins: [FixiOSBug],
    props: {
        // route object
        item: {
            type: Object,
            required: true,
        },
        isNest: {
            type: Boolean,
            default: false,
        },
        basePath: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            onlyOneChild: null,
        };
    },
    mounted() {
    },
    methods: {
        hasOneShowingChild(children, parent) {
            const showingChildren = children.filter((item) => {
                if (item.hidden) {
                    return false;
                } else {
                    // Temp set(will be used if only has one showing child)
                    this.onlyOneChild = item;
                    return true;
                }
            });

            // When there is only one child router, the child router is displayed by default
            if (showingChildren.length === 1) {
                return true;
            }

            // Show parent if there are no child router to display
            if (showingChildren.length === 0) {
                this.onlyOneChild = {
                    ...parent,
                    path: "",
                    noShowingChildren: true,
                };
                return true;
            }

            return false;
        },
        resolvePath(routePath) {
            if (isExternal(routePath)) {
                return routePath;
            }
            return path.resolve(this.basePath, routePath);
        },

        //获取页面操作权限
        getCurrentButtonByMenuId(id) {
            userRoleApi.getCurrentButtonByMenuId(id).then((res) => {
                this.$store.commit("setRoleInfo", res.content);
            });
        },

        //获取操作权限
        getMeunRole(data) {
            // this.getCurrentButtonByMenuId(data.route.id);
            window.localStorage.setItem("routerId", data.route.id);
        },

        generateTitle,
    },
};
</script>
