var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"comNotificationsItem"},[_c('div',{staticClass:"head"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.form.noticeType))]),_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm.form.noticeTime))])]),_c('div',{staticClass:"infoBox"},[_c('p',{staticClass:"normal ellipsisRow3"},[_vm._v("【"+_vm._s(_vm.form.tag)+"】 "+_vm._s(_vm.form.content))]),_c('div',{staticClass:"itemBox"},[(_vm.form.noticeType === '部门工时' && _vm.form.jsonStr)?[_c('el-row',{attrs:{"gutter":10}},_vm._l((_vm.form.jsonStr),function(item,idx){return _c('el-col',{key:idx,attrs:{"span":12}},[_c('div',{staticClass:"item",staticStyle:{"height":"24px"}},[_c('span',{staticClass:"info ellipsis w80"},[_vm._v(_vm._s(item.executorName))]),_c('span',{staticClass:"count"},[_vm._v(_vm._s(item.totalHour)+"h")])])])}),1)]:_vm._e(),(
                    ['每日出库设备统计', '新增入库', '新增出库', '每日入库设备统计'].indexOf(
                        _vm.form.noticeType
                    ) !== -1 && _vm.form.jsonStr
                )?[_c('el-row',{attrs:{"gutter":10}},_vm._l((_vm.form.jsonStr),function(item,idx){return _c('el-col',{key:idx,attrs:{"span":12}},[_c('div',{staticClass:"item",staticStyle:{"height":"24px"}},[_c('span',{staticClass:"info ellipsis w80"},[_vm._v(_vm._s(item.empType))]),_c('span',{staticClass:"count"},[_vm._v(_vm._s(item.num))])])])}),1)]:_vm._e(),(_vm.form.noticeType === '设备遗失' && _vm.form.jsonStr)?[_c('el-row',{attrs:{"gutter":10}},_vm._l((_vm.form.jsonStr),function(item,idx){return _c('el-col',{key:idx,attrs:{"span":12}},[_c('div',{staticClass:"item"},[_c('p',{staticClass:"info ellipsis w100"},[_vm._v(_vm._s(item.empType))]),_c('p',{staticClass:"smallInfo"},[_vm._v(_vm._s(item.qrCode))])])])}),1)]:_vm._e(),(_vm.form.noticeType === '新员工账户配置' && _vm.form.jsonStr)?[_c('el-row',{attrs:{"gutter":10}},_vm._l((_vm.form.jsonStr),function(item,idx){return _c('el-col',{key:idx,attrs:{"span":8}},[_c('div',{staticClass:"item"},[_c('span',{staticClass:"info ellipsis"},[_vm._v(" "+_vm._s(item.userName)+"（"+_vm._s(item.job || '暂无')+"） ")])])])}),1)]:_vm._e(),(
                    ['跟进项目', '跟进项目机会'].indexOf(_vm.form.noticeType) !== -1 && _vm.form.jsonStr
                )?_vm._l((_vm.form.jsonStr),function(item,idx){return _c('div',{key:idx,staticClass:"followResBox"},[_c('div',{staticClass:"head ofh"},[_c('span',{staticClass:"fll lh24"},[_vm._v(_vm._s(item.createUserName))]),_c('div',{staticClass:"flr tar ellipsis",staticStyle:{"width":"220px"}},_vm._l((item.followPersonList),function(it,i){return _c('span',{key:i,staticClass:"ml15"},[_c('i',{staticClass:"iconfont wideal-icon-user mr5"}),_vm._v(" "+_vm._s(it)+" ")])}),0)]),_c('p',{staticClass:"ellipsis",domProps:{"innerHTML":_vm._s(item.content)}}),(item.labels)?_c('p',{staticClass:"tipBox"},_vm._l((item.labels.split(',')),function(it,idx){return _c('span',{key:idx,staticClass:"tip ",class:{
                                red: it === '日常跟进',
                                green: it === '费用相关',
                                grey: it === '领导沟通'
                            }},[_vm._v(" "+_vm._s(it)+" ")])}),0):_vm._e()])}):_vm._e()],2)]),_c('div',{staticClass:"mask"},[_c('div',{staticClass:"controlBox"},[_c('span',{staticClass:"btn",on:{"click":_vm.toDetail}},[_vm._v("查看")]),(!_vm.form.looked)?_c('span',{staticClass:"btn",on:{"click":function($event){return _vm.readNotification()}}},[_vm._v("标为已读")]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }