// 消息小项
<template>
    <div class="comNotificationsItem" v-loading="isLoading">
        <div class="head">
            <span class="title">{{ form.noticeType }}</span>
            <span class="time">{{ form.noticeTime }}</span>
        </div>
        <div class="infoBox">
            <p class="normal ellipsisRow3">【{{ form.tag }}】 {{ form.content }}</p>
            <div class="itemBox">
                <!-- ✔ 部门工时 -->
                <template v-if="form.noticeType === '部门工时' && form.jsonStr">
                    <el-row :gutter="10">
                        <el-col :span="12" v-for="(item, idx) in form.jsonStr" :key="idx">
                            <div class="item" style="height: 24px">
                                <span class="info ellipsis w80">{{ item.executorName }}</span>
                                <span class="count">{{ item.totalHour }}h</span>
                            </div>
                        </el-col>
                    </el-row>
                </template>
                <!-- ✔ 设备 -->
                <template
                    v-if="
                        ['每日出库设备统计', '新增入库', '新增出库', '每日入库设备统计'].indexOf(
                            form.noticeType
                        ) !== -1 && form.jsonStr
                    "
                >
                    <el-row :gutter="10">
                        <el-col :span="12" v-for="(item, idx) in form.jsonStr" :key="idx">
                            <div class="item" style="height: 24px">
                                <span class="info ellipsis w80">{{ item.empType }}</span>
                                <span class="count">{{ item.num }}</span>
                            </div>
                        </el-col>
                    </el-row>
                </template>
                <!-- ✔ 遗失 -->
                <template v-if="form.noticeType === '设备遗失' && form.jsonStr">
                    <el-row :gutter="10">
                        <el-col :span="12" v-for="(item, idx) in form.jsonStr" :key="idx">
                            <div class="item">
                                <p class="info ellipsis w100">{{ item.empType }}</p>
                                <p class="smallInfo">{{ item.qrCode }}</p>
                            </div>
                        </el-col>
                    </el-row>
                </template>
                <!-- ✔ 人员 -->
                <template v-if="form.noticeType === '新员工账户配置' && form.jsonStr">
                    <el-row :gutter="10">
                        <el-col :span="8" v-for="(item, idx) in form.jsonStr" :key="idx">
                            <div class="item">
                                <span class="info ellipsis">
                                    {{ item.userName }}（{{ item.job || '暂无' }}）
                                </span>
                            </div>
                        </el-col>
                    </el-row>
                </template>
                <!-- ✔ 跟进记录 -->
                <template
                    v-if="
                        ['跟进项目', '跟进项目机会'].indexOf(form.noticeType) !== -1 && form.jsonStr
                    "
                >
                    <div class="followResBox" v-for="(item, idx) in form.jsonStr" :key="idx">
                        <div class="head ofh">
                            <span class="fll lh24">{{ item.createUserName }}</span>
                            <div class="flr tar ellipsis" style="width: 220px">
                                <span
                                    class="ml15"
                                    v-for="(it, i) in item.followPersonList"
                                    :key="i"
                                >
                                    <i class="iconfont wideal-icon-user mr5"></i>
                                    {{ it }}
                                </span>
                            </div>
                        </div>
                        <p class="ellipsis" v-html="item.content"></p>
                        <p class="tipBox" v-if="item.labels">
                            <span
                                class="tip "
                                :class="{
                                    red: it === '日常跟进',
                                    green: it === '费用相关',
                                    grey: it === '领导沟通'
                                }"
                                v-for="(it, idx) in item.labels.split(',')"
                                :key="idx"
                            >
                                {{ it }}
                            </span>
                        </p>
                    </div>
                </template>
            </div>
        </div>
        <!-- 遮罩层 -->
        <div class="mask">
            <div class="controlBox">
                <span class="btn" @click="toDetail">查看</span>
                <span class="btn" v-if="!form.looked" @click="readNotification()">标为已读</span>
            </div>
        </div>
    </div>
</template>

<script>
import API from '@/api/notificationConfigAPI.js';
export default {
    name: 'comNotificationsItem',

    props: {
        // 显示内容
        dataInfo: {
            type: Object,
            default() {
                return {};
            }
        }
    },

    components: {},

    data() {
        return {
            form: {},
            isLoading: false // 是否正在加载
        };
    },

    created() {},

    mounted() {
        this.form = Object.assign({}, this.dataInfo);
        try {
            this.form.jsonStr = JSON.parse(this.form.jsonStr);
        } catch (err) {
            this.form.jsonStr = null;
        }
    },

    methods: {
        // 跳转到详情
        toDetail() {
            // 单个消息标记为已读
            this.readNotification(true);
        },
        // 单个消息标记为已读
        readNotification(needToDetails = false) {
            this.isLoading = true;
            API.readNotification(this.dataInfo.id)
                .then(() => {
                    this.isLoading = false;
                    this.$message({
                        message: '操作成功！',
                        type: 'success'
                    });
                    this.$emit('update');
                    if (needToDetails) {
                        let url = this.dataInfo.url;
                        let menuId = url.slice(url.indexOf('menuId=') + 7);
                        menuId && window.localStorage.setItem('routerId', menuId);
                        window.location.href = url;
                        this.$emit('close');
                    }
                })
                .catch(() => {
                    this.isLoading = false;
                });
        }
    },

    computed: {},

    watch: {}
};
</script>

<style lang="scss" scoped>
p {
    padding: 0;
    margin: 0;
}
.lh24 {
    line-height: 24px;
}
.comNotificationsItem {
    padding: 16px 30px 18px;
    border-bottom: 1px solid #f2f4f7;
    position: relative;
    .head {
        line-height: 44px;
        .title {
            font-size: 16px;
            font-weight: 600;
            color: #303133;
        }
        .time {
            float: right;
            font-size: 14px;
            color: #666666;
        }
    }
    p.normal {
        color: #666;
        margin-bottom: 10px;
    }
    .mask {
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: rgba(0, 21, 40, 0.4);
        border-radius: 0px;
        .controlBox {
            position: absolute;
            overflow: hidden;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
        .btn {
            cursor: pointer;
            display: inline-block;
            min-width: 58px;
            padding: 0 15px;
            width: auto;
            height: 32px;
            line-height: 32px;
            background: #fff;
            margin: 0 13px;
            text-align: center;
            border-radius: 5px;
            font-size: 14px;
            color: #0091fb;
        }
    }
}
.infoBox {
    padding: 0;
}
.comNotificationsItem:hover .mask {
    opacity: 1;
    transition: all 0.5s;
}
// 小项信息
.itemBox {
    padding: 0;
    .item {
        min-height: 24px;
        line-height: 24px;
        background: #f2f5f8;
        border: 1px solid #e4e7ed;
        border-radius: 2px;
        margin-bottom: 10px;
        padding: 0 6px;
        .info {
            font-size: 12px;
            color: #333333;
        }
        .w100 {
            width: 100%;
        }
        .w80 {
            display: inline-block;
            width: 80%;
        }
        .count {
            width: 20%;
            float: right;
            font-size: 14px;
            font-weight: 600;
            color: #333333;
            text-align: right;
        }
        .smallInfo {
            font-size: 12px;
            font-weight: 400;
            color: #666666;
        }
    }
}
// 跟进记录
.followResBox {
    min-height: 76px;
    padding: 6px;
    background: #f2f5f8;
    border: 1px solid #e4e7ed;
    border-radius: 2px;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    color: #333333;
    .head {
        line-height: 1;
        padding-right: 14px;
    }
    .tip {
        margin-right: 6px;
    }
    .ellipsis {
        margin: 6px 0;
    }
}
</style>
