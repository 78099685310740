// 修改用户信息
<template>
    <el-dialog :close-on-click-modal="false"
               title="用户信息修改"
               :visible="true"
               width="952px"
               :before-close="close">

        <el-row class="userInfoContainer" v-loading="isloading">
            <el-row class="usnerInfoRow">
                <el-col :span="15"
                        class="colRow">
                    <h1>{{userInfo.userName}}</h1>
                    <el-row class="itemInfo">
                        <el-col :span="10">账户：{{userInfo.uaccount || ''}} </el-col>
                        <el-col :span="14">角色：{{userInfo.roleName || ''}} </el-col>
                    </el-row>
                    <el-row class="itemInfo">
                        <el-col :span="10">部门：{{userInfo.dingTalkDept || ''}} </el-col>
                        <el-col :span="14">岗位：{{userInfo.position || ''}} </el-col>
                    </el-row>
                    <el-row class="itemInfo">
                        <el-col :span="10">职位：{{userInfo.job || ''}} </el-col>
                        <el-col :span="14">身份证号码：{{userInfo.idCard || ''}}</el-col>
                    </el-row>
                </el-col>
                <el-col :span="9"
                        class="userPhotoBox">
                    <el-row class="photoRow">
                        <el-image :src="commonJs.showFileImg(userInfo.headPic) || require('@/assets/imgs/default_userPhoto.png')"
                                  alt=""
                                  fit="cover"
                                  class="userImg"></el-image>
                        <img :src="userInfo.sex.name ==='MAN' ? require('@/assets/icon/icon_sexMan.png') : require('@/assets/icon/icon_sexWuman.png')"
                             alt=""
                             class="sex">
                    </el-row>
                    <el-row class="photoRow">
                        <el-upload class="upload-demo"
                                   name="file"
                                   :headers="configHeader"
                                   :action="action"
                                   :on-success="uploadSuccess"
                                   :before-upload="beforeUpload"
                                   :on-error="uploadError"
                                   multiple
                                   :show-file-list="false">
                            <button class="blueBtn"
                                    v-loading="loadingUpUser">替换头像</button>
                        </el-upload>
                        <button class="blueBtn blueBtn_active"
                                @click="changePwd">修改密码</button>
                    </el-row>
                </el-col>
            </el-row>

            <el-row class="usnerInfoRow">
                <el-col :span="15"
                        class="colRow">
                    <h1 class="title">绑定信息</h1>
                    <el-row class="itemInfo paddingLeft16">
                        手机号码：{{userInfo.tel || ''}} <button @click="syncDing"
                                v-loading="loadingSyncDing"
                                class="blueBtn upBtnMargin">同步钉钉</button>
                    </el-row>
                    <el-row class="itemInfo paddingLeft16">
                        钉钉账号：{{userInfo.dingTalkAccount || ''}}
                    </el-row>
                </el-col>
                <el-col :span="9"
                        class="userPhotoBox flexCenter"
                        style="height:135px">
                    <el-row class="itemInfo">
                        微信账号：{{userInfo.weiXinAccount || '暂未绑定微信账号'}} <button v-loading="loadingUnbindWeiXin"
                                @click="unbindWeiXin"
                                v-if="userInfo.wxUnionid"
                                class="blueBtn upBtnMargin">解除绑定</button>
                    </el-row>
                </el-col>
            </el-row>


            <el-row class="usnerInfoRow">
                <el-col
                        class="colRow">
                    <h1 class="title">账户信息</h1>
                        <div class="tableBox">
                            <el-table :data="userInfo.accounts"
                                        border
                                        stripe>
                                <el-table-column prop="accountHolder"
                                                    label="开户人"
                                                    align="left"
                                                    width="140">
                                </el-table-column>
                                <el-table-column prop="account"
                                                    label="账户"
                                                    align="left"
                                                    width="250">
                                </el-table-column>
                                <el-table-column prop="bankName"
                                                    label="开户行"
                                                    align="left">
                                </el-table-column>
                                <el-table-column label="操作"
                                                align="center"
                                                width="100">
                                <template slot-scope="scope">
                                    <el-link type="primary"
                                                @click.stop="editTable(scope.row)">编辑</el-link>
                                    <el-link type="primary"
                                                @click.stop="deleteTable(scope.row,scope.$index)">删除</el-link>
                                </template>
                                </el-table-column>
                            </el-table>
                            <div class="tableAddBtnBar" @click="openAddaccount"><i class="el-icon-plus"></i>添加账户</div>
                        </div>
                </el-col>
            </el-row>
        </el-row>
        <popEditUserPwd v-if="showEditPwd"
                        @close="closeEditPwdPop"></popEditUserPwd>
        <popAddAccount v-if="showAddAcount" :userAccountRowData="userAccountRowData"
        @close="showAddAcount = !showAddAcount" @refresh="getCurrentLoginUserInfo(true)"></popAddAccount>
    </el-dialog>
</template>
<script>
import humanResourcesManageApi from "@/api/humanResourcesManageApi.js";
import popEditUserPwd from "./popEditUserPwd.vue"; //修改密码
import popAddAccount from "./popAddAccount.vue"; //新增 修改账户
import API from "@/api/finance.js";
export default {
    name: "popEditUserInfo",
    components: {
        popEditUserPwd,
        popAddAccount
    },
    props: {},
    watch: {},
    data() {
        return {
            showEditPwd: false, //显示修改密码弹框
            showAddAcount: false, //显示新增修改账户
            isloading: false, //显示整个页面用户信息
            loadingUpUser: false, //上传头像
            loadingUnbindWeiXin: false, //微信解除绑定
            loadingSyncDing: false, //同步钉钉
            userAccountRowData: null, //新增/编辑用户账户时的账户信息
            userInfo: { sex: {},accounts:[{type:234}] }, //当前登录人信息
            rules: {
                money: [
                    {
                        required: true,
                        message: "请输入金额",
                    },
                ],
            },
        };
    },
    mounted() {
        this.getCurrentLoginUserInfo();
    },
    methods: {
        // 提交表单
        onSubmit() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.save();
                } else {
                    this.$message({
                        message: "请检查必填内容是否填写！",
                        type: "error",
                    });
                    return;
                }
            });
        },

        // 修改密码
        changePwd() {
            this.showEditPwd = true;
        },
        // 关闭弹窗
        close() {
            this.$emit("close");
        },

        //关闭
        closeEditPwdPop() {
            this.showEditPwd = false;
        },

        //上传成功
        uploadSuccess(e) {
            //上传图片id
            humanResourcesManageApi.changeHeadPic(e.content).then(() => {
                this.loadingUpUser = false;
                this.getCurrentLoginUserInfo(true);
            });
        },
        //上传之前
        beforeUpload() {
            this.loadingUpUser = true;
        },
        //上传失败
        uploadError() {
            this.loadingUpUser = false;
        },

        //用户信息同步
        getCurrentLoginUserInfo(update = false) {
            this.isloading = true;
            this.$store.dispatch("getCurrentLoginUserInfo", update)
                .then((res) => {
                    this.userInfo = res;
                }).finally(()=>{
                    this.isloading = false;
                });
        },

        //解除微信绑定
        unbindWeiXin() {
            this.loadingUnbindWeiXin = true;
            this.$store.dispatch("unbindWeiXin").then(()=>{
                this.loadingUnbindWeiXin = false;
                this.getCurrentLoginUserInfo(true);
            }).catch(()=> this.loadingUnbindWeiXin = false)
        },

        //钉钉同步电话
        syncDing() {
            this.loadingSyncDing = true;
            this.$store.dispatch("syncDingTalkTel").then(()=>{
                this.loadingSyncDing = false;
                this.getCurrentLoginUserInfo(true);
            }).catch(()=> this.loadingSyncDing = false)
        },

        //打开账户弹框
        openAddaccount(){
            this.userAccountRowData=null;
            this.showAddAcount = true;
        },

        //编辑账户
        editTable(row){
            this.userAccountRowData = row;
            this.showAddAcount = true;
        },

        //删除账户
        deleteTable(row) {
            this.$confirm("确认删除吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                    this.isloading = true;
                    API.deleteAccount(row.id)
                        .then((res) => {
                            this.$message({
                                message: res.message || "删除成功",
                                type: "success",
                            });
                            this.getCurrentLoginUserInfo(true);
                    })
            });
        },
    },
    computed: {
        configHeader() {
            return { "access-token": this.commonJs.getCookie("ERPToken") };
        },

        action() {
            return window.configPath.baseUrl + "/filem/upload";
        },
    },
};
</script>
<style scoped>
.userInfoContainer {
    background-color: #fff;
    padding: 10px 15px;
    box-sizing: border-box;
}
.userInfoContainer .usnerInfoRow:nth-child(1),.userInfoContainer .usnerInfoRow:nth-child(2) {
    border-bottom: 1px solid #e4e4e5;
    padding-bottom: 20px;
    margin-bottom: 30px;
}
.userInfoContainer h1 {
    color: #262626;
    font-size: 25px;
    margin-bottom: 25px;
}
.userInfoContainer .itemInfo {
    margin-bottom: 20px;
    font-size: 14px;
    color: #595959;
}
h1,
h2,
h3,
p {
    margin: 0;
}

.userPhotoBox {
    position: relative;
}
.userPhotoBox .photoRow {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.userPhotoBox .photoRow:last-child {
    justify-content: space-around;
    margin-top: 30px;
}
.userPhotoBox .photoRow .userImg {
    width: 88px;
    height: 88px;
    border-radius: 50%;
}
.userPhotoBox .photoRow .sex {
    position: absolute;
    width: 25px;
    height: 25px;
    left: calc(50% + 20px);
    bottom: 00px;
}

.blueBtn {
    width: 100px;
    height: 36px;
    line-height: 36px;
    font-size: 16px;
    color: #00a2e9;
    background-color: #fff;
    border: 1px solid #00a2e9;
    text-align: center;
    border-radius: 4px;
    box-sizing: border-box;
    cursor: pointer;
}

.blueBtn:focus {
    outline: none;
}

.blueBtn_active {
    color: #fff;
    background-color: #00a2e9;
    border: none;
}

.usnerInfoRow .title {
    font-size: 16px;
    padding-left: 16px;
    position: relative;
}
.usnerInfoRow .title:before {
    content: "";
    display: inline-block;
    width: 2px;
    height: 100%;
    background-color: #00a2e9;
    position: absolute;
    left: 0px;
    top: 0px;
}
.upBtnMargin {
    margin-left: 28px;
}

.flexCenter {
    display: flex;
    align-items: center;
}

.paddingLeft16 {
    padding-left: 16px;
    box-sizing: border-box;
}
</style>