<template>
    <el-dialog :close-on-click-modal="false"
               title="修改密码"
               :visible="true"
               width="500px"
               append-to-body
               :before-close="close">
        <el-form :model="form"
                 ref="form"
                 :rules="rules"
                 label-width="100px"
                 :inline="false"
                 size="normal">
            <el-form-item label="原密码"
                          prop="oldPwd">
                <el-input v-model="form.oldPwd"
                          show-password
                          placeholder="请输入旧密码"
                          clearable />
            </el-form-item>
            <el-form-item label="新密码"
                          prop="newPwd">
                <el-input v-model="form.newPwd"
                          show-password
                          placeholder="请输入新密码"
                          clearable />
            </el-form-item>
            <el-form-item label="确认新密码"
                          prop="secondNewPwd">
                <el-input v-model="form.secondNewPwd"
                          show-password
                          placeholder="请输入新密码"
                          clearable />
            </el-form-item>
            <el-form-item class="footer">
                <el-button type="danger"
                           @click="close">取消</el-button>
                <el-button type="primary"
                           v-loading="isloading"
                           @click="onSubmit">确认</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>
<script>
import commonAPI from "@/api/commonAPI.js";
// 加密
import encrypt from "@/utils/encrypt";
export default {
    name: "popEditUserPwd",
    props: {},
    watch: {},
    data() {
        return {
            form: {
                newPwd: "",
                secondNewPwd: "", // 确认密码
                oldPwd: "",
            },
            isloading: false,
            rules: {
                oldPwd: [
                    {
                        required: true,
                        message: "请输入旧密码",
                    },
                ],
                newPwd: [
                    {
                        required: true,
                        message: "请输入新密码",
                    },
                ],
                secondNewPwd: [
                    {
                        required: true,
                        message: "请输入新密码",
                    },
                ],
            },
        };
    },
    mounted() {},
    methods: {
        // 提交表单
        onSubmit() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.save();
                } else {
                    this.$message({
                        message: "请检查必填内容是否填写！",
                        type: "error",
                    });
                    return;
                }
            });
        },

        //修改密码
        save() {
            if (this.form.newPwd !== this.form.secondNewPwd) {
                this.$message({message: "请确认新密码是否一致！",type: "error"});
                return;
            }
            this.isloading = true;
            this.$store.dispatch("GetPublicKey").then(v=>{
                commonAPI
                    .resetPwd({
                        publicKey: v.content,
                        newPwd: encrypt(this.form.newPwd),
                        secondNewPwd: encrypt(this.form.secondNewPwd),
                        oldPwd: encrypt(this.form.oldPwd),
                    })
                    .then(() => {
                        this.isloading = false;
                        this.$message({
                            message: "密码修改成功，请重新登录！",
                            type: "success",
                        });
                        // 退出登录
                        this.$store.dispatch("LogOut")
                    })
                    .catch(() => {
                        this.isloading = false;
                    });
            }).catch(()=>this.isloading = false)
        },

        // 关闭弹窗
        close() {
            this.$emit("close");
        },
    },
};
</script>
<style scoped>
</style>