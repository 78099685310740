// 消息列表抽屉
// popNotificationsList
<template>

    <div class="popNotificationsList">
        <el-drawer :visible.sync="dialogVisible"
                   :modal-append-to-body="true"
                   :append-to-body="true"
                   direction="rtl"
                   size="428px"
                   :show-close="false"
                   custom-class="popNotificationsList"
                   :before-close="close">
            <el-tabs v-model="activeName"
                     @tab-click="handleClick">
                <el-tab-pane :label="`未读通知（${noticeCount}）`"
                             name="未读通知">
                    <div class="notReadLis"
                         v-infinite-scroll="loadMore"
                         :infinite-scroll-immediate="false">
                        <div class="timeBox"
                             v-for="(item,idx) in notificationsList"
                             :key="idx">
                            <div class="title"><i class="iconfont wideal-icon-time"></i>{{item.date}}</div>
                            <com-notifications-item v-for="it in item.noticeList"
                                                    :key="it.id"
                                                    @update="updateLis"
                                                    @close="close"
                                                    :dataInfo="it"></com-notifications-item>
                        </div>
                        <div class="emptyBg"
                             v-if="!notificationsList.length"></div>
                        <el-divider v-if="noMore">到底啦！</el-divider>
                    </div>
                    <div class="controlBox">
                        <div class="btn"
                             v-if="notificationsList.length"
                             @click="readAllNotification">全部标为已读</div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="已读通知"
                             name="已读通知">
                    <div class="readLis">
                        <div class="timeBox"
                             v-for="(item,idx) in notificationsList"
                             :key="idx">
                            <div class="title"><i class="iconfont wideal-icon-time"></i>{{item.date}}</div>
                            <com-notifications-item v-for="it in item.noticeList"
                                                    :key="it.id"
                                                    @update="updateLis"
                                                    @close="close"
                                                    :dataInfo="it"></com-notifications-item>
                        </div>
                        <div class="emptyBg"
                             v-if="!notificationsList.length"></div>
                    </div>
                </el-tab-pane>
            </el-tabs>

        </el-drawer>

    </div>
</template>

<script>
import ComNotificationsItem from "./comNotificationsItem";
import API from "@/api/notificationConfigAPI.js";
import { Loading } from "element-ui";
export default {
    name: "popNotificationsList",

    props: {},

    components: {
        ComNotificationsItem,
    },

    data() {
        return {
            dialogVisible: true,
            noticeCount: 0, // 未读消息数量
            activeName: "未读通知",
            searchForm: {
                page: 1,
                // receiveUserId: "", // 通知接收人id
                size: 20, // 当前页条数
            },
            notificationsList: [], // 消息列表
            noMore: false, // 到底啦
        };
    },

    created() {},

    mounted() {
        // 获取未读通知数量
        this.getNoticeCount();
        // 获取列表
        this.getList(1);
    },

    methods: {
        // 切换Tab
        handleClick() {
            this.notificationsList = [];
            // 获取列表
            this.getList(1);
        },
        // 获取未读通知数量
        getNoticeCount() {
            let loading = Loading.service({
                target: document.querySelector(".right-menu .notifications .wideal-icon-notifications"),
                // spinner: "el-icon-loading",
                background: "#172048"
            });
            API.getNoticeCount()
                .then((res) => {
                    this.noticeCount = res.content || 0;
                    loading.close();
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 获取列表
        getList(page) {
            let loading = Loading.service({
                target: document.querySelector(".popNotificationsList"),
            });
            let data = Object.assign({}, this.searchForm, {
                page: page || this.searchForm.page,
                looked: this.activeName === "已读通知" ? true : false, // 是否查看 true是 false否
            });
            API.getNotificationsList(data)
                .then((res) => {
                    loading.close();
                    if (data.page === 1) {
                        this.$set(this, "notificationsList", [
                            ...res.content.records,
                        ]);
                    } else {
                        this.notificationsList = this.notificationsList.concat(
                            res.content.records
                        );
                    }
                    if (!res.content.records.length) {
                        this.noMore = true;
                    } else {
                        this.noMore = false;
                    }
                    // this.total = res.content.total;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 获取下一页列表
        loadMore() {
            this.searchForm.page += 1;
            // 获取列表
            this.getList();
        },
        // 更新计数和列表
        updateLis() {
            // 获取未读通知数量
            this.getNoticeCount();
            // 获取列表
            this.getList(1);
            this.$emit("update")
        },
        // 全部消息标记为已读
        readAllNotification() {
            this.isLoading = true;
            API.readAllNotification()
                .then(() => {
                    this.isLoading = false;
                    this.$message({
                        message: "操作成功！",
                        type: "success",
                    });
                    // 更新计数和列表
                    this.updateLis();
                })
                .catch(() => {
                    this.isLoading = false;
                });
        },
        // 关闭弹窗
        close() {
            this.$emit("update");
            this.$emit("close");
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
.popNotificationsList {
    ::v-deep .el-drawer__header {
        display: none;
    }
    ::v-deep .el-tabs__nav-scroll {
        padding: 0 29px;
        height: 60px;
        line-height: 60px;
        font-size: 16px;
        color: #5e6c8a;
    }
    .el-tabs {
        height: 100vh;
    }
    .el-tab-pane {
        height: 100vh;
    }
    ::v-deep .el-tabs__content {
        height: 100vh;
    }
}
.notReadLis {
    height: calc(100vh - 140px);
    overflow-y: auto;
}
.readLis {
    height: calc(100vh - 70px);
    overflow-y: auto;
}
.timeBox {
    .title {
        height: 60px;
        line-height: 70px;
        padding: 0 30px;
        color: #0091fb;
        border-bottom: 1px solid #f2f4f7;
        i {
            margin-right: 10px;
        }
    }
}
.controlBox {
    height: 70px;
    position: relative;
    background: #fff;
    .btn {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        padding: 0 14px;
        width: auto;
        height: 32px;
        line-height: 32px;
        text-align: center;
        background: #ffffff;
        border: 1px solid #dddfe5;
        border-radius: 5px;
        cursor: pointer;
        font-size: 14px;
        color: #0091fb;
    }
}
</style>