<template>
    <section class="app-main">
        <transition name="fade-transform"
                    mode="out-in">
            <!-- <keep-alive :include="cachedViews">
                <router-view :key="key" />
            </keep-alive> -->
            <keep-alive v-if="$route.meta.keepAlive">
                <router-view />
            </keep-alive>
            <keep-alive v-if="!$route.meta.keepAlive">
                <router-view :key="key" />
            </keep-alive>
        </transition>
    </section>
</template>

<script>
export default {
    name: "AppMain",
    computed: {
        cachedViews() {
            return this.$store.state.tagsView.cachedViews;
        },
        key() {
            return this.$route.name
                ? this.$route.name + +new Date()
                : this.$route + +new Date();
        },
    },
};
</script>

<style scoped>
</style>

