<template>
    <div :class="classObj"
         class="app-wrapper">
        <div v-if="device==='mobile'&&sidebar.opened"
             class="drawer-bg"
             @click="handleClickOutside" />
        <sidebar class="sidebar-container" />
        <div class="main-container">
            <navbar />
            <!-- <tags-view /> -->
            <app-main />
        </div>

        <!-- 修改密码 -->
        <pop-change-pwd v-if="popChangePwdVisible"
                        :fistLogin="true"
                        @close="closepopChangePwd"></pop-change-pwd>
    </div>
</template>

<script>
import PopChangePwd from "../../components/popChangePwd";
import API from "@/api/commonAPI.js";
import {
    Navbar,
    Sidebar,
    AppMain,
    // TagsView
} from "./components";
import ResizeMixin from "./mixin/ResizeHandler";
import { Loading } from "element-ui";

export default {
    name: "Layout",
    components: {
        PopChangePwd,
        Navbar,
        Sidebar,
        AppMain,
        // TagsView
    },
    mixins: [ResizeMixin],
    data() {
        return {
            popChangePwdVisible: false, // 修改密码弹窗显示
        };
    },
    mounted() {
        // 是否第一次登录
        this.isFistLogin();
    },
    computed: {
        sidebar() {
            return this.$store.state.app.sidebar;
        },
        device() {
            return this.$store.state.app.device;
        },
        classObj() {
            return {
                hideSidebar: !this.sidebar.opened,
                openSidebar: this.sidebar.opened,
                withoutAnimation: this.sidebar.withoutAnimation,
                mobile: this.device === "mobile",
            };
        },
    },
    methods: {
        // 是否第一次登录
        isFistLogin() {
            let loading = Loading.service({
                target: document.querySelector("body"),
            });
            API.isFistLogin()
                .then((res) => {
                    if (res.content.isNeedUpdatePwd) {
                        // 修改密码 打开弹框
                        this.openpopChangePwd();
                    }
                    loading.close();
                })
                .catch(() => {
                    loading.close();
                    // 是否第一次登录
                    this.isFistLogin();
                });
        },
        // 修改密码 打开弹框
        openpopChangePwd() {
            this.popChangePwdVisible = true;
        },
        // 修改密码 关闭弹框
        closepopChangePwd() {
            this.popChangePwdVisible = false;
        },
        handleClickOutside() {
            this.$store.dispatch("closeSideBar", { withoutAnimation: false });
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "~@/styles/mixin.scss";

.app-wrapper {
    @include clearfix;
    position: relative;
    height: 100%;
    width: 100%;

    & .mobile.openSidebar {
        position: fixed;
        top: 0;
    }
}
.drawer-bg {
    background: #000;
    opacity: 0.3;
    width: 100%;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 999;
}
</style>
