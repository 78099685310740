//添加账户
<template>
    <el-dialog :close-on-click-modal="false"
               title="修改账户信息"
               :visible="true"
               width="500px"
               append-to-body
               :before-close="close">
        <el-form :model="form"
                 ref="form"
                 :rules="rules"
                 label-width="100px"
                 :inline="false"
                 size="normal">
            <el-form-item label="开户人"
                          prop="accountHolder">
                <el-input v-model="form.accountHolder"
                          placeholder="请输入开户人"
                          clearable />
            </el-form-item>
            <el-form-item label="账户"
                          prop="account">
                <el-input v-model="form.account"
                          placeholder="请输入账户"
                          clearable />
            </el-form-item>
            <el-form-item label="开户行"
                          prop="bankId">
                <selectAllBank  v-model="form.bankId"></selectAllBank>
            </el-form-item>
            <el-form-item class="footer">
                <el-button type="danger"
                           @click="close">取消</el-button>
                <el-button type="primary"
                           v-loading="isloading"
                           @click="onSubmit">确认</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>
<script>
import API from "@/api/finance.js";
// 加密
import selectAllBank from "@/modleComponents/account/selectAllBank"; //选择所有银行
export default {
    name: "popAddAcount",
    props: {
        userAccountRowData:{}
    },
    watch: {
        // 账户信息
        userAccountRowData: {
            deep: true,
            immediate: true,
            handler: function (data) {
                // setTimeout(() => {
                //     data && (this.form = this.commonJs.deepCopy(data));
                // }, 20);
                data && (this.form = this.commonJs.deepCopy(data));
            },
        },
    },
    components: {
        selectAllBank,
    },
    data() {
        return {
            form: {
                account:"",//账户
                bankId:"",//银行id
                accountHolder:"",//开户人
            },
            isloading: false,
            rules: {
                account: [
                    {
                        required: true,
                        message: "请输入账户",
                    },
                ],
                bankId: [
                    {
                        required: true,
                        message: "请选择开户行",
                    },
                ],
                accountHolder: [
                    {
                        required: true,
                        message: "请输入开户人",
                    },
                ],
            },
        };
    },
    mounted() {},
    methods: {
        // 提交表单
        onSubmit() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.save();
                } else {
                    this.$message({
                        message: "请检查必填内容是否填写！",
                        type: "error",
                    });
                    return;
                }
            });
        },

        //新增编辑用户账户
        save() {
            this.isloading = true;
            API.saveUserAccount(this.form)
                .then((res) => {
                    this.isloading = false;
                    this.$message({
                        message: res.message || "操作成功",
                        type: "success",
                    });
                    this.$emit("close");
                    this.$emit("refresh");
                })
                .catch(() => {
                    this.isloading = false;
            });
        },

        // 关闭弹窗
        close() {
            this.$emit("close");
        },
    },
};
</script>
<style scoped>
</style>