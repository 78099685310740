<template>
    <div class="navbar">
        <hamburger :toggle-click="toggleSideBar"
                   :is-active="sidebar.opened"
                   class="hamburger-container" />
        <!-- <breadcrumb class="breadcrumb-container" /> -->
        <div class="breadcrumb">
            <router-link to="/dashboard">主页</router-link>
            <!-- <router-link to
                         v-show="nowPathName"> / {{nowPathName}}</router-link> -->
            <a style="padding:0 5px;cursor:auto">/ {{nowPathName}}</a>
        </div>
        <div class="right-menu">
            <div class="applyCount">
                审批中心：{{needAuditCount}}
            </div>
            <div class="notifications">
                <el-badge :value="noticeCount"
                          :hidden="!noticeCount"
                          class="cup"
                          @click.native="openpopNotificationsList">
                    <i class="iconfont wideal-icon-notifications"></i>
                </el-badge>
            </div>
            <template v-if="device!=='mobile'">
                <!--<search class="right-menu-item" />-->
                <!--<error-log class="errLog-container right-menu-item hover-effect"/>-->
                <screenfull class="right-menu-item hover-effect" />
                <!--<el-tooltip :content="$t('navbar.size')" effect="dark" placement="bottom">-->
                <!--<size-select class="right-menu-item hover-effect"/>-->
                <!--</el-tooltip>-->
                <!--<lang-select class="right-menu-item hover-effect"/>-->
                <!--<el-tooltip :content="$t('navbar.theme')" effect="dark" placement="bottom">-->
                <!--<theme-picker class="right-menu-item hover-effect"/>-->
                <!--</el-tooltip>-->
            </template>

            <el-dropdown class="avatar-container right-menu-item hover-effect"
                         trigger="click">
                <div class="avatar-wrapper">
                    <!-- <img :src="require('@/assets/imgs/user-avatar.jpg')" -->
                    <el-image class="user-avatar"
                              :src="commonJs.showFileImg(userInfo.headPic) || require('@/assets/imgs/default_userPhoto.png')"
                              fit="cover"></el-image>
                    <span class="userName">{{userInfo.userName}}</span>
                    <i class="el-icon-caret-bottom" />
                </div>
                <el-dropdown-menu slot="dropdown">
                    <router-link to="/index">
                        <el-dropdown-item>
                            首页
                        </el-dropdown-item>
                    </router-link>
                    <el-dropdown-item divided>
                        <span style="display:block;"
                              @click="changePwd">修改信息
                        </span>
                    </el-dropdown-item>
                    <el-dropdown-item divided>
                        <span style="display:block;"
                              @click="logout">退出登录
                        </span>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>

        <!-- 消息列表抽屉 -->
        <pop-notifications-list v-if="popNotificationsListVisible"
                                @update="getNoticeCount"
                                @close="closepopNotificationsList"></pop-notifications-list>
        <!-- 修改用户信息 -->
        <popEditUserInfo v-if="editUser"
                         @close="close"></popEditUserInfo>
    </div>
</template>

<script>
import PopNotificationsList from "../../../components/Notifications/popNotificationsList";
import { mapGetters } from "vuex";
// import Breadcrumb from "@/components/Breadcrumb";
import Hamburger from "@/components/Hamburger";
import Screenfull from "@/components/Screenfull";
import popEditUserInfo from "./popup/popEditUserInfo";
//import ThemePicker from '@/components/ThemePicker'
import { Loading } from "element-ui";
// import { getUserName, getUserNameId } from "@/utils/auth";
// import commonAPI from "@/api/commonAPI.js";
// 加密
// import encrypt from "@/utils/encrypt";
import API from "@/api/approval.js";
import notificationConfigAPI from "@/api/notificationConfigAPI.js";
import WebSocket from "@/utils/WebSocket";

export default {
    data() {
        return {
            userInfo: this.$store
                .dispatch("getCurrentLoginUserInfo")
                .then((res) => {
                    this.userInfo = res;
                }), //当前登录人信息
            nowPathName: "",
            noticeCount: 0, // 未读消息数量
            editUser: false, //修改用户信息
            popNotificationsListVisible: false, // 消息列表抽屉弹窗显示
        };
    },
    components: {
        PopNotificationsList,
        // Breadcrumb,
        Hamburger,
        Screenfull,
        popEditUserInfo,
        //  ThemePicker,
    },
    computed: {
        ...mapGetters([
            "sidebar",
            "userName",
            "avatar",
            "device",
            "needAuditCount",
        ]),
    },
    mounted() {
        window.toDetail = this.toDetail; // 解决字符串模板@click无效的问题
        // 审批中心-列表数量
        this.approveCount();

        // 获取未读通知数量
        this.getNoticeCount();
        // TODO V3.24 开发时关闭websocket
        WebSocket.Init();
        // 接收消息
        WebSocket.onmessage = (res) => {
            console.log("接收到的消息", res);
            this.noticeMsg(res);
        };
    },
    methods: {
        // 获取未读通知数量
        getNoticeCount() {
            let loading = Loading.service({
                target: document.querySelector(
                    ".right-menu .notifications .wideal-icon-notifications"
                ),
                // spinner: "el-icon-loading",
                background: "#172048",
            });
            notificationConfigAPI
                .getNoticeCount()
                .then((res) => {
                    this.noticeCount = res.content || 0;
                    loading.close();
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 审批中心-列表数量
        approveCount() {
            let loading = Loading.service({
                target: document.querySelector(".applyCount"),
            });
            API.approveCountNoAuth()
                .then((res) => {
                    this.$store.commit("SET_NEEDAUDITCOUNT", res.content); // 待审批数量
                    loading.close();
                })
                .catch(() => {
                    loading.close();
                });
        },
        toggleSideBar() {
            this.$store.dispatch("toggleSideBar");
        },
        // 退出登录
        logout() {
            let loading = Loading.service({
                target: document.querySelector(".body"),
            });
            this.$store
                .dispatch("LogOut")
                .then(() => {
                    // 关闭websocket连接
                    WebSocket.close();
                    loading.close();
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 修改密码
        changePwd() {
            this.editUser = true;
        },
        // 通知弹窗
        noticeMsg(data = {}) {
            // 关闭时获取未读通知数量
            this.getNoticeCount();
            this.$notify({
                dangerouslyUseHTMLString: true,
                customClass: "notifyBox",
                offset: 100,
                duration: window.noticeConfig.duration,
                message: `
                    <div class="cup" onClick="toDetail('${data.id}','${
                    data.url
                }')">
                        <div class="head">
                            <span class="title">${data.noticeType}</span>
                            <span class="time">${data.noticeTime}</span>
                        </div>
                        <div class="info ellipsis2">
                            【${data.tag}】 ${data.content || "暂无"}
                        </div>
                    </div>
                    `,
                onClose: () => {
                    // 关闭时获取未读通知数量
                    // this.getNoticeCount();
                },
            });
        },
        // 跳转到详情
        toDetail(id, url) {
            notificationConfigAPI.readNotification(id).then(() => {
                let menuId = url.slice(url.indexOf("menuId=") + 7);
                menuId && window.localStorage.setItem("routerId", menuId);
                // 关闭时获取未读通知数量
                this.getNoticeCount();
                window.location.href = url;
            });
        },

        close() {
            this.editUser = false;
        },
        // 消息列表抽屉 打开弹框
        openpopNotificationsList() {
            this.popNotificationsListVisible = true;
        },
        // 消息列表抽屉 关闭弹框
        closepopNotificationsList() {
            this.popNotificationsListVisible = false;
        },
    },
    watch: {
        $route: {
            deep: true,
            immediate: true,
            handler: function (to) {
                if (to.meta.title === "主页") {
                    this.nowPathName = "";
                } else {
                    this.nowPathName = to.meta.title;
                }
            },
        },

        //用户信息
        "$store.state.user.userInfo": {
            deep: true,
            immediate: true,
            handler: function (newVal) {
                newVal && (this.userInfo = newVal);
            },
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.navbar {
    height: 50px;
    overflow: hidden;

    .hamburger-container {
        line-height: 46px;
        height: 100%;
        float: left;
        cursor: pointer;
        transition: background 0.3s;

        & :hover {
            background: rgba(0, 0, 0, 0.025);
        }
    }

    .breadcrumb-container {
        float: left;
    }

    .errLog-container {
        display: inline-block;
        vertical-align: top;
    }
    .breadcrumb {
        float: left;
    }
    .right-menu {
        float: right;
        height: 100%;
        line-height: 50px;

        & :focus {
            outline: none;
        }

        .right-menu-item {
            display: inline-block;
            padding: 0 8px;
            height: 100%;
            font-size: 18px;
            color: #5a5e66;
            vertical-align: text-bottom;
            margin-right: 40px;

            & .hover-effect {
                cursor: pointer;
                transition: background 0.3s;

                & :hover {
                    background: rgba(0, 0, 0, 0.025);
                }
            }
        }

        .avatar-container {
            margin-right: 30px;

            .avatar-wrapper {
                position: relative;

                .user-avatar {
                    cursor: pointer;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    vertical-align: sub;
                    margin-right: 10px;
                }
                .userName {
                    vertical-align: super;
                    padding-left: 10px;
                    cursor: pointer;
                    color: #ffffff;
                }
                .el-icon-caret-bottom {
                    cursor: pointer;
                    position: absolute;
                    right: -20px;
                    top: 50%;
                    transform: translateY(-70%);
                    font-size: 12px;
                    color: #ffffff;
                }
            }
        }
    }
}

.el-dropdown-menu--medium .el-dropdown-menu__item {
    text-align: center;
}

// 审批中心
.applyCount {
    float: left;
    min-width: 156px;
    height: 42px;
    line-height: 42px;
    border-radius: 10px;
    font-size: 16px;
    padding: 0 20px 0 50px;
    margin-right: 0px;
    margin-top: 22px;
    color: #ffffff;
    background: #24a3ff url("../../../assets/imgs/icon_applyCount.png")
        no-repeat 20px center;
}
// 通知
.notifications {
    float: left;
    height: 42px;
    line-height: 42px;
    padding: 0 20px;
    margin: 20px 40px 0;
    i {
        border-radius: 10px;
        font-size: 16px;
        color: #ffffff;
    }
}
</style>
<style lang="scss">
// 通知弹窗样式
.el-notification__group {
    margin-left: 0;
}
.notifyBox {
    .head {
        .title {
            display: inline-block;
            width: 80%;
            font-size: 16px;
            font-weight: 600;
            line-height: 16px;
            color: #303133;
            margin-bottom: 6px;
        }
        .time {
            font-size: 14px;
            font-weight: 400;
            color: #666666;
        }
    }
    .info {
        width: 281px;
        height: 44px;
        line-height: 22px;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
    }
}
</style>