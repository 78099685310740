<template>
    <div class="screenfull">
        <svg-icon style="fill:#fff;"
                  :icon-class="isFullscreen?'exit-fullscreen':'fullscreen'"
                  @click="click" />
    </div>
</template>

<script>
import screenfull from "screenfull";

export default {
    name: "Screenfull",
    data() {
        return {
            isFullscreen: false,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        click() {
            if (!screenfull.enabled) {
                this.$message({
                    message: "you browser can not work",
                    type: "warning",
                });
                return false;
            }
            screenfull.toggle();
        },
        init() {
            if (screenfull.enabled) {
                screenfull.on("change", () => {
                    this.isFullscreen = screenfull.isFullscreen;
                });
            }
        },
    },
};
</script>

<style scoped>
.screenfull-svg {
    display: inline-block;
    cursor: pointer;
    fill: #5a5e66;
    width: 20px;
    height: 20px;
    vertical-align: 10px;
}
</style>
